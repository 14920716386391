import { modalIds } from '@/constants';

function CartItem(props: { name: string; amount: number; price: number }) {
  return (
    <div className="pb-4 flex flex-col md:flex-row gap-8 justify-between items-center md:items-end border border-solid border-t-0 border-r-0 border-l-0 border-[#DFDFDF]">
      <div className="flex flex-col md:flex-row gap-4">
        <img width={115} height={115} src="/cart-item.png" />
        <div className="flex flex-col gap-4 justify-between">
          <div className="flex flex-col gap-4">
            <div className="text-[16px] text-black">{props.name}</div>
            <div className="text-[25px] font-bold text-black">
              {props.amount} spotkań
            </div>
          </div>
          <div className="text-[25px] font-bold text-black">
            {props.price} zł
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.16992 14.83L14.8299 9.17004"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.8299 14.83L9.16992 9.17004"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="font-bold text-[16px] text-black">Usuń</div>
      </div>
    </div>
  );
}

export function CartModal() {
  return (
    <dialog
      id={modalIds.cart}
      className="modal !border-0 w-screen h-screen bg-transparent"
    >
      <div className="modal-box !w-[80%] lg:!w-[91%] h-screen fixed right-6">
        <div className="flex flex-col justify-between h-full gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between gap-4 border border-solid border-t-0 border-r-0 border-l-0 border-black pb-4">
              <div className="text-black font-bold text-[16px]">
                TWÓJ KOSZYK - 5 PRZEDMIOTÓW
              </div>
              <form method="dialog">
                <button className="border-none bg-transparent cursor-pointer">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.16992 14.83L14.8299 9.17004"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.8299 14.83L9.16992 9.17004"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </form>
            </div>
            <CartItem name="Pakiet mentalny" amount={5} price={1200} />
          </div>
          <button
            onClick={() => window.location.assign('/checkout')}
            className="text-white bg-[#4396FF] rounded-[69px] p-4 border-none"
          >
            Przejdź do zamawiania
          </button>
        </div>
      </div>
    </dialog>
  );
}
