import { modalIds } from '@/constants';
import { useState } from 'react';
import ReactDropdown from 'react-dropdown';

export function BasicModal() {
  const [option, setOption] = useState<string | undefined>();

  const options = [
    { value: '1', label: '1 spotkanie 300zł' },
    { value: '2', label: '3 spotkania 750zł' },
    { value: '3', label: '5 Spotkań 1200zł' },
    { value: '4', label: 'Mentalne warsztaty edukacyjne 350zł' },
    { value: '5', label: 'Mentalne szkolenia biznesowe 3500zł' },
  ];

  return (
    <dialog id={modalIds.basic} className="modal !border-0">
      <div className="modal-box !w-[80%] lg:!w-[91%] min-h-[400px]">
        <div className="flex flex-col justify-between gap-4 h-[400px]">
          <div className="flex flex-col gap-4">
            <div className="text-[25px] font-bold text-black">
              Zamów trening mentalny
            </div>
            <ReactDropdown
              options={options}
              onChange={(e) => setOption(e.value)}
              value={option}
              placeholder="Wybierz pakiet"
              controlClassName="border border-solid border-[#313131] rounded-[69px] p-4"
              arrowClassName="top-6 right-6"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <form method="dialog">
              <button className="w-full bg-transparent p-4 rounded-[59px] border border-solid border-[#313131] cursor-pointer">
                Anuluj
              </button>
            </form>
            <button
              onClick={() => window.location.assign('/checkout?id=' + option)}
              className="p-4 rounded-[59px] border-none text-white bg-[#4396FF] cursor-pointer"
            >
              Zamawiam
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
}
