export const API_URL =
  'http://api.annajaworska.edu.pl/' ?? 'http://localhost:8080';

export const modalIds = {
  basic: 'basic-modal',
  cart: 'cart-modal',
};

export const products = [
  {
    id: 1,
    name: '1 spotkanie',
    price: 300,
  },
  {
    id: 2,
    name: '3 spotkania',
    price: 750,
  },
  {
    id: 3,
    name: 'Pakiet mentalny - 5 spotkań',
    price: 1200,
  },
  {
    id: 4,
    name: 'Mentalne warsztaty edukacyjne',
    price: 350,
  },
  {
    id: 5,
    name: 'Mentalne szkolenia biznesowe',
    price: 3500,
  },
];
