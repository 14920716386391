import { modalIds } from '@/constants';
import { defaultStyles } from '@/utils/defaultStyles';

export const routes = [
  {
    label: 'Usługi',
    id: 'services',
  },
  {
    label: 'O mnie',
    id: 'about-me',
  },
  {
    label: 'Oferta',
    id: 'offer',
  },
  {
    label: 'Kontakt',
    id: 'contact',
  },
];

export function ModalButton(props: { secondary?: boolean }) {
  return (
    <button
      onClick={() =>
        (
          document.getElementById('basic-modal') as HTMLDialogElement | null
        )?.showModal()
      }
      className={`cursor-pointer text-[16px] font-semibold flex flex-row justify-center items-center outline-none rounded-[63px] py-4 px-4 w-[180px] border border-solid ${props.secondary ? 'text-white bg-transparent border-white' : 'text-[#0D81FF] bg-white border-transparent'}`}
    >
      <div>Umów wizytę</div>
    </button>
  );
}

function Navbar() {
  return (
    <div className={`bg-[#4396FF] ${defaultStyles.paddingBig}`}>
      <nav className="relative navbar bg-transparent h-[108px] py-4">
        <div className="navbar-start flex flex-row items-center">
          <img
            loading="lazy"
            src="/logo.png"
            className="w-[123px] h-[123px] cursor-pointer"
            alt="Mental step logo"
            onClick={() => window.location.assign('/')}
          />
        </div>
        <div className="navbar-center hidden xl:flex flex-row gap-8">
          {routes.map((item, index) => (
            <div
              key={index}
              className="text-white text-[16px] cursor-pointer btn btn-ghost"
              onClick={() => {
                if (item.id) {
                  if (window.location.pathname != '/') {
                    window.location.assign('/');
                  }
                  document
                    .getElementById(item.id)!
                    .scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="navbar-end flex flex-row gap-4">
          <div className="flex xl:hidden flex-row items-center gap-2">
            <div className="dropdown dropdown-end text-white z-40">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost btn-circle"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h7"
                  />
                </svg>
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow text-black"
              >
                {routes.map((item, index) => (
                  <li key={index}>
                    <div
                      onClick={() => {
                        if (item.id) {
                          if (window.location.pathname != '/') {
                            window.location.assign('/');
                          }
                          document
                            .getElementById(item.id)!
                            .scrollIntoView({ behavior: 'smooth' });
                        }
                      }}
                    >
                      {item.label}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-white text-[16px] hidden lg:flex">Menu</div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
